import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@Environment';

import { Profile } from './types/profile.type';

@Injectable()
export class AuthRepository {
  public constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  public login(email: string): Observable<void> {
    return this.httpClient.post<void>(`${environment.authApiUrl}v1/auth/magic-link`, { email });
  }

  public verifyLogin(email: string, otp: string): Observable<void> {
    return this.httpClient.post<void>(`${environment.authApiUrl}v1/auth/magic-link/verify`, {
      otp,
      email,
    });
  }

  public oidc(idToken: string): Observable<void> {
    return this.httpClient.post<void>(`${environment.authApiUrl}v1/auth/oidc`, { idToken });
  }

  public getProfile(skip?: boolean): Observable<Profile> {
    return this.httpClient.get<Profile>(`${environment.authApiUrl}v1/auth/me`, {
      headers: {
        skip: skip ? String(skip) : '',
      },
    });
  }

  public logout(): Observable<void> {
    return this.httpClient.get<void>(`${environment.authApiUrl}v1/auth/me/logout`);
  }
}
